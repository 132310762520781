.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  color: black;
  font-size: 2rem;
}

.fadeout-horizontal {
  mask-image: linear-gradient(
    right,
    transparent,
    white 5rem,
    white calc(100% - 5rem) transparent
  );
}

.marquee-text {
  overflow: clip;
}

.marquee-text-track {
  display: flex;
  padding-left: 4.8rem;
  gap: 4.8rem;
  width: max-content;
  animation: marquee-move-text var(--speed, 10s) linear infinite
    var(--direction, forwards);
}

.marquee-text p {
  border: 1px solid gray;
  background-color: white;
  border-radius: 999px;
  padding: 1rem 2.5rem;
}

@keyframes marquee-move-text {
  to {
    transform: translateX(-50%);
  }
}

.cardContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0px;
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -25%;
  height: 500px;
  width: 100%;
  margin: 0 300px 0 300px;
  border-radius: 25px;
  padding: 50px;
  transform-origin: top;
}

.card h2 {
  text-align: center;
  margin: 0px;
  font-size: 28px;
}

.card .body {
  display: flex;
  height: 100%;
  margin-top: 50px;
  gap: 50px;
}

.card .body .description {
  width: 40%;
  position: relative;
  top: 10%;
}

.card .body .description p {
  font-size: 16px;
}

.card .body .description p::first-letter {
  font-size: 28px;
  font-family: "Title";
}

.card .body .description span {
  display: flex;
  align-items: center;
  gap: 5px;
}

.card .body .description span a {
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.card .body .imageContainer {
  position: relative;
  width: 60%;
  height: 100%;
  border-radius: 25px;
  overflow: hidden;
}

.card .body .imageContainer .inner {
  width: 100%;
  height: 100%;
}

.card .body .imageContainer img {
  object-fit: cover;
}

.grid-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.grid-container .box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.grid-container .box .dream {
  display: flex;
  flex-direction: column;
  width: 32.5%;
}

.grid-container .box .dream img {
  width: 100%;
  padding-bottom: 5px;
  border-radius: 5px;
}
